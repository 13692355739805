import React, { useState, useEffect, useRef } from 'react';
import './ProductsDisplay.css'; // Make sure to import the CSS file for styling

const ProductDisplay = ({ title, images, backgroundImage, description, features, videos, onAddProduct, resetIndexes, onResetComplete }) => {
  const [isShrunk, setIsShrunk] = useState(false);
  const scrollableRef = useRef(null);
  const [counter, setCounter] = useState(0);
  const [showAddedLabel, setShowAddedLabel] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showFadeOut, setShowFadeOut] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State to track the current image index
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0); // State to track the current title index

  const handleScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop } = scrollableRef.current;
      // Check if the scrollable content is overlapping the image
      setIsShrunk(scrollTop > 0);
      setIsButtonVisible(scrollTop === 0);
      // Toggle blur on scrollable content
      if (scrollTop > 0) {
        scrollableRef.current.classList.remove('blur');
      } else {
        scrollableRef.current.classList.add('blur');
      }
    }
  };

  useEffect(() => {
    const scrollableContent = scrollableRef.current;
    if (scrollableContent) {
      scrollableContent.addEventListener('scroll', handleScroll);
      // Initially set the scrollable content to blurred
      scrollableContent.classList.add('blur');
    }

    return () => {
      if (scrollableContent) {
        scrollableContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const incrementCounter = () => {
    setCounter(prevCounter => prevCounter + 1);
  };

  const decrementCounter = () => {
    setCounter(prevCounter => (prevCounter > 0 ? prevCounter - 1 : 0));
  };

  const handleAddProduct = () => {
    // Start the fade-in transition
    setShowFadeOut(false);
    setShowAddedLabel(true);
    onAddProduct(title[currentTitleIndex], counter);
    setCounter(0);

    // After the label has been shown for 1.3 seconds, start the fade-out transition
    setTimeout(() => {
      setShowFadeOut(true);

      // After the fade-out transition duration (0.3s), set the label visibility to false
      setTimeout(() => {
        setShowAddedLabel(false);
      }, 300); // This should match the duration of your CSS transition for fade-out
    }, 1300); // Duration the label is visible before starting the fade-out
  };

  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleTitleChange = (index) => {
    setCurrentTitleIndex(index);
    setCurrentImageIndex(0); // Reset image index when title changes
  };

  useEffect(() => {
    if (resetIndexes) {
      setCurrentTitleIndex(0);
      setCurrentImageIndex(0);
      onResetComplete(); // Notify parent component that reset is complete
    }
  }, [resetIndexes, onResetComplete]);

  return (
    <div className="product-display">
      <div className={`background-image ${isShrunk ? 'blur' : ''}`} style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <h2 className={isShrunk ? 'shrink' : ''}>{title[currentTitleIndex]}</h2>
      <div className={`counter-container ${isShrunk ? 'shrink' : ''}`}>
      </div>
      {showAddedLabel && (
        <div className={`added-label ${!showFadeOut ? 'added-label-show' : 'added-label-hide'}`}>
          Producto agregado
        </div>
      )}
      <div className="image-container">
        <div className={`image-selector ${isShrunk ? 'shrink' : ''}`} style={{ zIndex: isShrunk ? 9 : 11 }}>
          {images[currentTitleIndex].map((image, index) => (
            <button
              key={index}
              className={`image-selector-button ${currentImageIndex === index ? 'active' : ''}`}
              onClick={() => handleImageChange(index)}
            >
              <img src={image} alt={`Thumbnail ${index}`} />
            </button>
          ))}
        </div>
        <img src={images[currentTitleIndex][currentImageIndex]} alt={title[currentTitleIndex]} className={isShrunk ? 'shrink' : ''} />
        <div className={`title-selector ${isShrunk ? 'shrink' : ''}`} style={{ zIndex: isShrunk ? 9 : 11 }}>
          {title.map((title, index) => (
            <button
              key={index}
              className={`title-selector-button ${currentTitleIndex === index ? 'active' : ''}`}
              onClick={() => handleTitleChange(index)}
            >
              <img src={images[index][0]} alt={`Title ${index}`} />
            </button>
          ))}
        </div>
      </div>
      <div className="scrollable-content" ref={scrollableRef}>
        <p>{description}</p>
        <ul className="features">
          <hr />
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
          <hr />
        </ul>
        {videos && videos.length > 0 && (
          <div className="videos">
            {videos.map((video, index) => (
              <video key={index} controls>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}
          </div>
        )}
        <div className="counter-controls">
          <button className="counter-button" onClick={decrementCounter} disabled={counter === 0}>-</button>
          <span className="counter-value">{counter}</span>
          <button className="counter-button" onClick={incrementCounter}>+</button>
        </div>
        <button className="add-button" onClick={handleAddProduct} disabled={counter === 0}>Agregar</button>
      </div>
    </div>
  );
};

export default ProductDisplay;
