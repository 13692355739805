import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import AgeVerification from './components/AgeVerification';
import MainApp from './MainApp';
import './App.css';

import prohi from './assets/prohi.png';

function App() {
  const [isAgeVerified, setIsAgeVerified] = useState(false);
  const [isUnderage, setIsUnderage] = useState(false);

  useEffect(() => {
    const ageVerified = Cookies.get('ageVerified');
    const underage = Cookies.get('underage');

    if (ageVerified) {
      setIsAgeVerified(true);
    } else if (underage) {
      setIsUnderage(true);
    }
  }, []);

  const handleAgeVerified = (isOver21) => {
    if (isOver21) {
      Cookies.set('ageVerified', 'true', { expires: 7 }); // Set cookie to expire in 7 days
      setIsAgeVerified(true);
    } else {
      Cookies.set('underage', 'true', { expires: 7 }); // Set cookie to expire in 7 days
      setIsUnderage(true);
    }
  };

  if (!isAgeVerified) {
    return isUnderage ? (
      <div className="underage-message">
        <img src={prohi} alt="Prohibited" className="prohibited-image" />
      </div>
    ) : (
      <AgeVerification onAgeVerified={handleAgeVerified} />
    );
  }

  return <MainApp />;
}

export default App;
