// AgeVerification.js
import React from 'react';
import './AgeVerification.css';

import veri from '../assets/veri.png';

const AgeVerification = ({ onAgeVerified }) => {
  const handleAgeVerification = (isOver21) => {
    onAgeVerified(isOver21);
  };

  return (
    <div className="age-verification">
      <img src={veri} alt="Verification" className="verification-image" />
      <h1 className="age-verification-title">Verificación de Edad</h1>
      <p className="age-verification-subtitle">Necesitas ser mayor de 21 años para ingresar</p>
      <button onClick={() => handleAgeVerification(true)}>Soy mayor de 21 años</button>
      <button onClick={() => handleAgeVerification(false)}>Soy menor de 21 años</button>
    </div>
  );
};

export default AgeVerification;
