import vapeImage from '../assets/vape1_.png';
import vapeImage2 from '../assets/vape2_.png';
import vapeImage3 from '../assets/vape3_.png';

import backIm from '../assets/rivers1_.png';

import catalogoImage from '../assets/cbd1.png';
import productosNuevosImage from '../assets/cbd3.png';
import promocionesImage from '../assets/cbd2.png';

import catalogo from '../assets/Titulos/Catalogo.png';
import productos_nuevos from '../assets/Titulos/Productos_Nuevos.png';
import promociones from '../assets/Titulos/Promociones.png';

//Cupsy
import cupsy1 from '../assets/Productos/PUFFCO/Cupsy/Black/1.png';
import cupsy2 from '../assets/Productos/PUFFCO/Cupsy/Black/2.png';
import cupsy3 from '../assets/Productos/PUFFCO/Cupsy/Black/3.png';
import cupsy4 from '../assets/Productos/PUFFCO/Cupsy/Black/4.png';

//Peak
import peak1 from '../assets/Productos/PUFFCO/Peak/1.png';
import peak2 from '../assets/Productos/PUFFCO/Peak/2.png';
import peak3 from '../assets/Productos/PUFFCO/Peak/3.png';

//Peak Pro Desert
import peak_pro1 from '../assets/Productos/PUFFCO/Peak_Pro/Desert/1.png';
import peak_pro2 from '../assets/Productos/PUFFCO/Peak_Pro/Desert/2.png';
import peak_pro3 from '../assets/Productos/PUFFCO/Peak_Pro/Desert/3.png';
import peak_pro4 from '../assets/Productos/PUFFCO/Peak_Pro/Desert/4.png';

//Peak Pro Negro
import peak_pro_1 from '../assets/Productos/PUFFCO/Peak_Pro/Negro/1.png';
import peak_pro_2 from '../assets/Productos/PUFFCO/Peak_Pro/Negro/2.png';
import peak_pro_3 from '../assets/Productos/PUFFCO/Peak_Pro/Negro/3.png';
import peak_pro_4 from '../assets/Productos/PUFFCO/Peak_Pro/Negro/4.png';

//Peak Pro Negro
import peak_pro_1_ from '../assets/Productos/PUFFCO/Peak_Pro/Verde/1.png';
import peak_pro_2_ from '../assets/Productos/PUFFCO/Peak_Pro/Verde/2.png';
import peak_pro_3_ from '../assets/Productos/PUFFCO/Peak_Pro/Verde/3.png';
import peak_pro_4_ from '../assets/Productos/PUFFCO/Peak_Pro/Verde/4.png';
import peak_pro_video1 from '../assets/Productos/PUFFCO/Peak_Pro/Videos/How to Clean - Peak Pro.mp4';
import peak_pro_video2 from '../assets/Productos/PUFFCO/Peak_Pro/Videos/How to Use - Peak Pro.mp4';

//Proxy Desert
import proxy1 from '../assets/Productos/PUFFCO/Proxy/Desert/1.png';
import proxy2 from '../assets/Productos/PUFFCO/Proxy/Desert/2.png';
import proxy3 from '../assets/Productos/PUFFCO/Proxy/Desert/3.png';
import proxy4 from '../assets/Productos/PUFFCO/Proxy/Desert/4.png';

//Proxy Black
import proxy_1 from '../assets/Productos/PUFFCO/Proxy/Black/1.png';
import proxy_2 from '../assets/Productos/PUFFCO/Proxy/Black/2.png';
import proxy_3 from '../assets/Productos/PUFFCO/Proxy/Black/3.png';
import proxy_4 from '../assets/Productos/PUFFCO/Proxy/Black/4.png';
import proxy_video1 from '../assets/Productos/PUFFCO/Proxy/Videos/Proxy - How to clean.mp4';
import proxy_video2 from '../assets/Productos/PUFFCO/Proxy/Videos/Proxy - How to Use.mp4';

//Stunden
import stunden1 from '../assets/Productos/Stunden/StundenGlass/3.png';
import stunden2 from '../assets/Productos/Stunden/StundenGlass/4.png';
import stunden3 from '../assets/Productos/Stunden/StundenGlass/5.png';
import stunden4 from '../assets/Productos/Stunden/StundenGlass/6.png';
import stunden5 from '../assets/Productos/Stunden/StundenGlass/7.png';
import stunden_video1 from '../assets/Productos/Stunden/StundenGlass/Videos/video1.mp4';

const productsData = {
	"Catálogo": {
	showProximamente: false,
	titleImage: catalogo,
	items: [
		{
			id: 0,
			title: ["Peak"],
			images: [
			  [peak3, peak2, peak1]
			],
			image: peak3,
		        backgroundImage: backIm,
		        description: "El Peak lo cambia todo. Nuestro vaporizador inteligente original hace que las experiencias con dabs sean más accesibles que nunca. Con una filtración de agua premium para golpes más suaves, cuatro configuraciones de calor y una interfaz de un solo botón, el Peak hace que disfrutar tu dabs sea mas fácil.",
		        features: ["$ 4,599.00 MXN"],
		        videos: []
		},
		{
			id: 1,
			title: ["Peak Pro Desert", "Peak Pro Negro", "Peak Pro Verde"],
			images: [
			  [peak_pro4, peak_pro2, peak_pro1, peak_pro3],
			  [peak_pro_1, peak_pro_2, peak_pro_3, peak_pro_4],
			  [peak_pro_1_, peak_pro_2_, peak_pro_3_, peak_pro_4_]
			],
			image: peak_pro1,
                        backgroundImage: backIm,
                        description: "El nuevo Peak Pro es la cumbre de la tecnología de dabs. Este vaporizador inteligente premium ofrece un sabor increíble y un rendimiento inigualable. Desbloquea el máximo potencial de tu dabs con su interfaz de un solo botón, personalización profunda a través de la aplicación Puffco Connect y su deposito 3D patentado. Con la tapa Joystick y las ranuras perc cortadas con láser para una mejor filtración de agua.",
                        features: ["$ 8,799.00 MXN"],
                        videos: [peak_pro_video1, peak_pro_video2]
		},
		{
			id: 2,
			title: ["Proxy Kit Desert", "Proxy Kit Black"],
			images: [
			  [proxy1, proxy2, proxy3, proxy4],
			  [proxy_2, proxy_1, proxy_3, proxy_4]
			],
			image: proxy1,
                        backgroundImage: backIm,
                        description: "El Proxy propone una versatilidad y tecnología inigualable en la palma de tu mano. Este vaporizador compacto utiliza un deposito 3D patentado para ofrecer un sabor premium, con cuatro configuraciones de calor y una interfaz de un solo botón, el Proxy está diseñado para crecer contigo.",
                        features: ["$ 6,599.00 MXN"],
                        videos: [proxy_video1, proxy_video2]
		},
		{
			id: 3,
			title: ["Stundenglass Gravity Infuser"],
			images: [
			  [stunden1, stunden2, stunden3, stunden4, stunden5]
			],
			image: stunden1,
                        backgroundImage: backIm,
                        description: "El Proxy propone una versatilidad y tecnología inigualable en la palma de tu mano. Este vaporizador compacto utiliza un deposito 3D patentado para ofrecer un sabor premium, con cuatro configuraciones de calor y una interfaz de un solo botón, el Proxy está diseñado para crecer contigo.",
                        features: ["$ 6,599.00 MXN"],
                        videos: [stunden_video1]
		},
		{
			id: 4,
			title: ["Cupsy"],
			images: [
			  [cupsy1, cupsy2, cupsy3, cupsy4]
			],
			image: cupsy1,
                        backgroundImage: backIm,
                        description: "Oculto a simple vista. Cupsy es una pipa de agua premium disfrazada de una simple taza de café. Su discreto y sencillo cuerpo de acero inoxidable contiene un sistema de burbujas de alto rendimiento, hecho de silicona y plástico para una fácil limpieza. Enciende el deposito de flor de cerámica y guárdalo en el compartimento oculto cuando hayas terminado: Cupsy mantiene las cosas portátiles y discretas.",
                        features: ["$ 1,499.00 MXN"],
                        videos: []
		}
		]
	},
	"Productos Nuevos": {
	showProximamente: true,
	titleImage: productos_nuevos,
	items: [
		{
			id: 0,
			title: ["Delta 8"],
			images: [
			  [vapeImage, vapeImage2, vapeImage3]
			],
			image: vapeImage,
		        backgroundImage: backIm,
		        description: "El cannabidiol (CBD) es uno de los muchos compuestos activos encontrados en la planta de cannabis.",
		        features: ["THC: 90% - 92%", "$ 1,200.00 MXN"],
		        videos: []
		},
		{
			id: 1,
			title: ["CBD Isolate"],
			images: [
			  [vapeImage, vapeImage2, vapeImage3]
			],
			image: vapeImage2,
                        backgroundImage: backIm,
                        description: "El CBD puede tener efectos ansiolíticos, ayudando a reducir la ansiedad y el estrés.",
                        features: ["THC: 94% - 96%", "$ 1,200.00 MXN"],
                        videos: []
		},
		{
			id: 2,
			title: ["CBD Full Spectrum"],
			images: [
			  [vapeImage, vapeImage2, vapeImage3]
			],
			image: vapeImage3,
                        backgroundImage: backIm,
                        description: "Muchas personas utilizan el CBD para mejorar la calidad del sueño y tratar el insomnio.",
                        features: ["THC: 92% - 96%", "$ 950.00 MXN"],
                        videos: []
		}
		]
	},
	"Promociones": {
	showProximamente: true,
	titleImage: promociones,
	items: [
		{
			id: 0,
			title: ["Delta 8"],
			images: [
			  [vapeImage, vapeImage2, vapeImage3]
			],
			image: vapeImage,
		        backgroundImage: backIm,
		        description: "El cannabidiol (CBD) es uno de los muchos compuestos activos encontrados en la planta de cannabis.",
		        features: ["THC: 90% - 92%", "$ 1,200.00 MXN"],
		        videos: []
		},
		{
			id: 1,
			title: ["CBD Isolate"],
			images: [
			  [vapeImage, vapeImage2, vapeImage3]
			],
			image: vapeImage2,
                        backgroundImage: backIm,
                        description: "El CBD puede tener efectos ansiolíticos, ayudando a reducir la ansiedad y el estrés.",
                        features: ["THC: 94% - 96%", "$ 1,200.00 MXN"],
                        videos: []
		},
		{
			id: 2,
			title: ["CBD Full Spectrum"],
			images: [
			  [vapeImage, vapeImage2, vapeImage3]
			],
			image: vapeImage3,
                        backgroundImage: backIm,
                        description: "Muchas personas utilizan el CBD para mejorar la calidad del sueño y tratar el insomnio.",
                        features: ["THC: 92% - 96%", "$ 950.00 MXN"],
                        videos: []
		}
		]
	}
}

const additionalInfo = [
	"Mirando hacia el futuro, esperamos con mucho entusiasmo que pronto en México se permita la comercialización legal del THC recreativo, para poder expandir nuestra oferta de productos y trabajar con los mejores productores de THC, tanto nacionales como extranjeros. Mientras tanto, nos estamos posicionando en el mercado, generando una comunidad más grande e informada que consuma con responsabilidad el cannabis. Asimismo, cumpliendo con nuestro principal objetivo, que es la “des – satanización del cannabis”.\n“...la verdad os hará libres” (Juan 8:32)",
  "Producto utilizado: HHC (hexahidrocannabinol), elaborado en los laboratorios de nuestros socios a partir de cannabinoides naturales y extracto de cannabis. El sistema endocannabinoide es un sistema de comunicación intercelular que regula diversas funciones en el cuerpo humano. Los fitocannabinoides, como el THC y el HHC, interactúan con los receptores cannabinoides en este sistema. El THC estimula las neuronas en el sistema de recompensa para liberar dopamina, lo que produce efectos psicoactivos, en cambio el HHC se considera menos psicoactivo y más suave, lo que lo hace una opción más segura y eficaz.",
  "Beneficios de consumir HHC: \n• Efectividad contra el dolor muscular y efecto antiinflamatorio: El HHC tiene propiedades analgésicas y antiinflamatorias, lo que lo convierte en un candidato para el tratamiento de dolores crónicos e inflamaciones. • Estimulación del apetito: Al igual que otros cannabinoides, el HHC puede estimular el apetito, siendo útil en el tratamiento de trastornos alimenticios o en pacientes con pérdida de apetito por diversas causas. • Relajación: El HHC ofrece una sensación de relajación sin provocar una somnolencia significativa. • Potenciador creativo: Algunos consumidores afirman experimentar un aumento en la creatividad y enfoque mental al utilizar productos que contienen HHC."
  ];
  
  const sectionImages = {
	"Catálogo": catalogoImage,
	"Productos Nuevos": productosNuevosImage,
	"Promociones": promocionesImage
  };

export { additionalInfo, productsData, sectionImages };
