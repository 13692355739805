import React, { useState, useRef, useCallback, useEffect } from 'react';
import { additionalInfo, productsData, sectionImages } from './components/productData';
import ProductDisplay from './components/ProductsDisplay';
import logo from './assets/Logos/MainLogo/Logo0_.png';
import mainLogo from './assets/Logos/MainLogo/Logo0_.png';
import './App.css';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from 'react-slick';

import backButton from './assets/symbols/back_arrow3.png';
import cartButton from './assets/symbols/cart_logo2.png';
import noHay from './assets/no_hay.png';
import proximamente from './assets/symbols/proximamente.png'; // Import the proximamente image

const MainApp = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const sliderRef = useRef(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isProductInfoVisible, setIsProductInfoVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const appBodyRef = useRef(null);
  const [addedProducts, setAddedProducts] = useState({});
  const [isCartVisible, setIsCartVisible] = useState(false);
  const menuButtonRefs = useRef([]);
  const [resetIndexes, setResetIndexes] = useState(false);
  const [logoClass, setLogoClass] = useState('main-logo');

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
    if (isMenuVisible && isSubMenuVisible) {
      // If both menus are visible, close the second menu as well
      setIsSubMenuVisible(false);
      setCurrentTextIndex(0);
    }
  };

  const toggleSubMenu = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
    if (isSubMenuVisible) {
      setCurrentTextIndex(0);
    }
  };

  const toggleCart = () => {
    setIsCartVisible(!isCartVisible);
  };

  const goToPreviousText = () => {
    setCurrentTextIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const goToNextText = () => {
    setCurrentTextIndex((prevIndex) => (prevIndex < additionalInfo.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const toggleProductInfo = () => {
    setLogoClass('main-logo-exit');
    setTimeout(() => {
      setIsProductInfoVisible(!isProductInfoVisible);
      setSelectedSection(null); // Reset selected section
      setResetIndexes(true); // Reset indexes when toggling product info
      setLogoClass('main-logo');
    }, 500); // Match the duration of the animation
  };

  const handleMainLogoClick = () => {
    if (isProductInfoVisible) {
      toggleProductInfo();
    }
    setSelectedSection(null); // Reset selected section
    setResetIndexes(true); // Reset indexes when main logo is clicked
    setLogoClass('main-logo-exit');
    setTimeout(() => {
      setLogoClass('main-logo');
    }, 500); // Match the duration of the animation
  };

  const handleSectionClick = (sectionName) => {
    setSelectedSection(sectionName);
    setIsProductInfoVisible(true); // Ensure product info is visible when a section is clicked
    setResetIndexes(false); // Ensure resetIndexes is false when a section is clicked
    setLogoClass('title-image-exit');
    setTimeout(() => {
      setLogoClass('title-image');
    }, 500); // Match the duration of the animation
  };

  const handleResetComplete = () => {
    setResetIndexes(false); // Set resetIndexes back to false after reset is complete
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable the default arrows
    afterChange: current => {
      setCurrentSlide(current); // Update the current slide index after change
      // Reset the scroll position of the .App-body element
      if (appBodyRef.current) {
        appBodyRef.current.scrollTop = 0;
      }
    }
  };

  const updateAddedProducts = useCallback((title, quantityChange) => {
    setAddedProducts((prevAddedProducts) => {
      const newAddedProducts = { ...prevAddedProducts };
      const currentQuantity = newAddedProducts[title] || 0;
      const newQuantity = currentQuantity + quantityChange;

      if (newQuantity > 0) {
        newAddedProducts[title] = newQuantity;
      } else {
        delete newAddedProducts[title];
      }

      return newAddedProducts;
    });
  }, []);

  const incrementProductQuantity = (title) => {
    updateAddedProducts(title, 1);
  };

  const decrementProductQuantity = (title) => {
    updateAddedProducts(title, -1);
  };

  const openWhatsAppChat = () => {
    let message = 'Hola, me interesan algunos de sus productos:';
    for (const [title, quantity] of Object.entries(addedProducts)) {
      message += `\n${quantity} x ${title}`; // %0A is the URL-encoded newline character
    }
    const phoneNumber = '+525618441772';
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  useEffect(() => {
    // Group the buttons into rows based on their index
    const rows = menuButtonRefs.current.reduce((acc, button, index) => {
      const rowIndex = Math.floor(index / 3) * 2; // Calculate the row index (each row has 3 buttons)
      if (!acc[rowIndex]) {
        acc[rowIndex] = [];
      }
      if (index % 3 !== 2 && button) { // Exclude the rectangle buttons and check if button is not null
        acc[rowIndex].push(button);
      }
      return acc;
    }, []);

    // Set the height of buttons in the same row to match the tallest button
    rows.forEach((row) => {
      if (row.length > 0) {
        const maxHeight = Math.max(...row.map(button => button?.offsetHeight || 0)); // Use optional chaining and provide a fallback value
        row.forEach(button => {
          if (button) { // Check if button is not null before setting the style
            button.style.height = `${maxHeight}px`;
          }
        });
      }
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <button className="burger-button" onClick={toggleMenu}>
          <div className="burger-line"></div>
          <div className="burger-line"></div>
          <div className="burger-line"></div>
        </button>
        <img src={cartButton} className="whatsapp-button" alt="Cart" onClick={toggleCart} />
        <button className="App-logo-main" onClick={handleMainLogoClick}>
          <div className="logo-container">
            {selectedSection && !resetIndexes && productsData[selectedSection].titleImage ? (
              <img src={productsData[selectedSection].titleImage} alt={selectedSection} className={logoClass} />
            ) : (
              <img src={mainLogo} alt="Main Logo" className={logoClass} />
            )}
          </div>
        </button>
      </header>

      <div className={`side-menu ${isMenuVisible ? 'visible' : ''}`}>
        <div className="close-menu" onClick={toggleMenu}>
          <div className="line1"></div>
          <div className="line2"></div>
        </div>
        <div className="menu-logo">
          <img src={logo} alt="React Logo" className="menu-react-logo" />
        </div>
        <div className="menu-description">
          <p>En Mayan Weed reconocemos al 100% el potencial del cannabis, especialmente de uno de sus muchos derivados el CBD. Este proyecto está sustentado por la aceptación del cannabis entre la comunidad médica-científica. Por lo cual sabemos que el humano cuenta con un sistema endocannabinoide denominado (SEC), el cual permite la recepción y absorción de este en el organismo. Se ha comprobado con diversos testimonios de pacientes ajenos a nosotros, mejoras en tratamientos para el dolor crónico, trastorno de sueño, ansiedad, incluso para mejorar la movilidad y disminuir el dolor que provoca la artritis en las articulaciones.</p>
        </div>

        <button className="saber-mas-btn" onClick={toggleSubMenu}>Saber Más...</button>

        <div className={`sub-side-menu ${isSubMenuVisible ? 'visible' : ''}`}>
          <div className="close-sub-menu" onClick={toggleSubMenu}>
            <div className="line1"></div>
            <div className="line2"></div>
          </div>
          {/* Content for the second sliding menu */}
          <div className="sub-menu-description">
            <p>{additionalInfo[currentTextIndex]}</p>
          </div>
          <div className="text-slider-arrows">
            <button onClick={goToPreviousText} disabled={currentTextIndex === 0}>&lt;</button>
            <button onClick={goToNextText} disabled={currentTextIndex === additionalInfo.length - 1}>&gt;</button>
          </div>
        </div>
      </div>

      <div className={`cart-window ${isCartVisible ? 'visible' : ''}`}>
        <div className="close-cart" onClick={toggleCart}>
          <div className="line1"></div>
          <div className="line2"></div>
        </div>
        <h2>Carrito</h2>
        {Object.keys(addedProducts).length === 0 ? (
          <div className="empty-cart">
            <img src={noHay} alt="No hay productos" className="empty-cart-image" />
          </div>
        ) : (
          <div className="cart-items">
            {Object.entries(addedProducts).map(([title, quantity]) => (
              <div key={title} className="cart-item">
                <span>{title}</span>
                <div className="quantity-controls">
                  <button onClick={() => decrementProductQuantity(title)}>-</button>
                  <span>{quantity}</span>
                  <button onClick={() => incrementProductQuantity(title)}>+</button>
                </div>
              </div>
            ))}
          </div>
        )}
        <button className="comprar-btn" onClick={openWhatsAppChat}>Comprar</button>
      </div>

      <main className="App-body">
        <div className="home">
          <p></p>
          <div className="menu-buttons">
            {Object.entries(productsData).map(([sectionName, sectionData], index) => {
              // Determine the class for the button based on its position
              const isRectangle = (index + 1) % 3 === 0 || index === Object.entries(productsData).length - 1;
              const buttonClass = isRectangle ? "menu-button-rectangle" : "menu-button-square";

              const sectionImage = sectionImages[sectionName];

              return (
                <div key={index} className={`menu-button-wrapper ${buttonClass}`} style={{ animationDelay: `${index * 0.2}s` }}>
                  <button
                    className="menu-button"
                    onClick={() => handleSectionClick(sectionName)}
                    ref={el => menuButtonRefs.current[index] = el} // Assign ref to button
                  >
                    {sectionImage && <img src={sectionImage} alt={sectionName} className="section-image" />}
                    {sectionName}
                  </button>
                </div>
              );
            })}
          </div>
          <div className={`product-info-menu ${isProductInfoVisible ? 'visible' : ''}`}>
            <button className="close-product-info-btn" onClick={toggleProductInfo}>
              <img src={backButton} alt="Back" className="back-button-img" />
            </button>
            <div className="close-product-info" onClick={toggleProductInfo}>
              {/* Close button, similar to the existing menus */}
            </div>
            {selectedSection && (
              <div className="product-section">
                
                {productsData[selectedSection].showProximamente ? (
                  <img src={proximamente} alt="Proximamente" className="proximamente-image" />
                ) : (
                  <Slider ref={sliderRef} {...settings}>
                    {productsData[selectedSection].items.map((product) => (
                      <ProductDisplay
                        key={product.id}
                        title={product.title}
                        images={product.images}
                        backgroundImage={product.backgroundImage}
                        description={product.description}
                        features={product.features}
                        reviews={product.reviews}
                        videos={product.videos}
                        onAddProduct={updateAddedProducts}
                        resetIndexes={resetIndexes}
                        onResetComplete={handleResetComplete}
                      />
                    ))}
                  </Slider>
                )}
                <div className="footer-images-container">
  {productsData[selectedSection].items.map((product, index) => (
    <div
      key={product.id}
      className={`footer-point ${currentSlide === index ? 'active' : ''}`}
      onClick={() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(index);
        }
      }}
    ></div>
  ))}
</div>
                <div className="footer-layer footer-layer-1"></div>
                <div className="footer-layer footer-layer-2"></div>
                <div className="footer-layer footer-layer-3"></div>
                <div className="footer-layer footer-layer-4"></div>
                <div className="pseudo-footer"></div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default MainApp;
